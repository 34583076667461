import { Link } from "gatsby";
import React from "react";

import styles from "./footerLarge.module.css";
import BlockContent from "./sanity/block-content";

const FooterLarge = ({
    siteTitle,
    siteLogo,
    siteFooterTextFirst,
    siteFooterTextSecond,
    siteFooterTextThird,
  }) => (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.footerInnerGrid}>
          <div className={styles.gridItem}>
            <BlockContent blocks={siteFooterTextFirst} />
          </div>
  
          <div className={styles.gridItem}>
            <BlockContent blocks={siteFooterTextSecond} />
          </div>
          
          <div className={styles.gridItem}>
            <BlockContent blocks={siteFooterTextThird} />
          </div>
  
        </div>
      </div>
    </footer>
  );
  
  export default FooterLarge;

  /* <div style={{ display:"flex", flexGrow: '1', marginTop: "1rem" }}>
              <Carbonbadge darkMode={false} />
            </div> */