import React from "react";
import Header from "./header";
import Footer from "./footerLarge";
import "../styles/layout.css";

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, displaySiteTitle, siteLogo, siteFooterTextFirst, siteFooterTextSecond, siteFooterTextThird, navMenuItems}) => (
    <>
        <Header siteTitle={siteTitle} siteLogo={siteLogo} displaySiteTitle={displaySiteTitle} navMenuItems={navMenuItems} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
        <>{children}</>
        <Footer siteTitle={siteTitle} siteLogo={siteLogo} siteFooterTextFirst={siteFooterTextFirst} siteFooterTextSecond={siteFooterTextSecond} siteFooterTextThird={siteFooterTextThird}/>
    </>
)

export default Layout;