import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";

import CTALink from "./CTALink";
import { cn } from "../lib/helpers";
import styles from "./header.module.css";

const Header = ({ onHideNav, onShowNav, showNav, siteTitle, displaySiteTitle, siteLogo, navMenuItems = [] }) => {
    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.branding}>
            <Link id="siteTitle" to="/">
              <div className={styles.brandingTitle}>
                <img src={siteLogo} className={styles.brandingTitleLogo} alt={siteTitle} />
               <div style={{display: (displaySiteTitle && displaySiteTitle) || "block"}}>{siteTitle}</div>
              </div>
            </Link>
          </div>
  
          <button className={styles.toggleNavButton} aria-label="Open/Close Navigation Menu" alt="Åpne/lukke meny" onClick={showNav ? onHideNav : onShowNav}>
            <Icon symbol="hamburger" />
          </button>
  
          <nav className={cn(styles.nav, showNav && styles.showNav)}>
              <ul className={styles.navMenuItems}>
                {navMenuItems.map((i) => (
                  <li>
                    <CTALink {...i} buttonActionClass={styles.navActionClass} />
                  </li>
                ))}
              </ul>
          </nav>
        </div>
      </div>
    );
  };
  
  export default Header;